import { Loading } from "element-ui";
import axios from "axios";
import { Base64 } from "js-base64";
import { getCookie } from "./utils/utils";
let Token = getCookie("INewHome-Token");
let userInfo = getCookie("userInfo");
if (userInfo) {
  userInfo = JSON.parse(userInfo);
}

if (!Token || !userInfo) {
  window.location.href = `http://cloud.inewhome.com/#/login?redirectUrl=http://data.inewhome.com`;
}

//
// if(queryString('c')&&userInfo&&userInfo.company_id&&queryString('c')!=userInfo.company_id){
//   userInfo.company_id = "";
//   window.location.href = `http://data.inewhome.com`;
// }

axios.defaults.timeout = 10000;
//返回其他状态吗
axios.defaults.validateStatus = function(status) {
  return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;

//http request拦截
axios.interceptors.request.use(
  (config) => {
    //开启 progress bar
    // const isToken = meta.isToken === false;
    if (!config.headers.Authorization) {
      config.headers["Authorization"] = `Basic ${Base64.encode(
        `saber:saber_secret`
      )}`;
    }
    //让每个请求携带token
    if (Token) {
      config.headers["Blade-Auth"] = "bearer " + Token;
    }
    //headers中配置serialize为true开启序列化
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//跨域请求，允许保存cookie
let loadingInstance = "";
axios.defaults.withCredentials = true;
axios.interceptors.request.use(
  (config) => {
    loadingInstance = Loading.service({
      text: "拼命加载中",
      background: "rgba(0,0,0,0.5)",
      spinner: "el-icon-loading",
    });
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
//HTTPrequest拦截
axios.interceptors.response.use(
  (config) => {
    const status = config.data.code || config.status;
    if (status === 401) {
      window.location.href = `http://cloud.inewhome.com/#/login?redirectUrl=http://data.inewhome.com`;
    }
    loadingInstance.close();
    return config;
  },
  (error) => {
    return Promise.reject(new Error(error));
  }
);

export default axios;
