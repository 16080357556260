/** 
 * 自定义组件参考文档
 * https://cn.vuejs.org/v2/guide/components-registration.html
*/
import Vue from 'vue'
import Test from './test/';
//自定义产值组件
import Numdiy from './numdiy/';
//自定义自定义产值组件
import Productionvalue from './productionvalue/';
// 自定义地图组件
import Companymap from './companymap/';

// 自定义分公司组件
import Branchmap from './branchmap/';

// 自定义交付地图组件
import Delivercompanymap from './delivercompanymap/';

// 自定义交付分公司组件
import Deliverbranchmap from './deliverbranchmap/';

// 自定义公司名称组件
import Companyname from './companyname/';

// 自定义表格
import Linkabletable from './linkabletable/';

const list = [
  Test,
  Numdiy,
  Companymap,
  Branchmap,
  Companyname,
  Linkabletable,
  Productionvalue,
  Delivercompanymap,
  Delivercompanymap
]
//循环注册组件
list.forEach(ele => {
  ele.mixins = [window.AVUE.$Echart]
  Vue.component(`avue-echart-${ele.name}`, ele)
})