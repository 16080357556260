<template>
  <div :key="id" :style="{ fontSize: fontSize,color:'#BBFFFF'}" class="root">
    <span :style="{ fontSize: lableFontSize }" class="numdiy-name">{{
      numTitle
    }}</span>
    <div class="progress">
      <span class="percent" :style="{ width: percent + '%',background:numBackgroundColor0}">{{
        (numStr.percent?numStr.percent:0 )+ "%"
      }}</span>
    </div>

  <span class="value">{{ numStr.value?numStr.value:0 }}</span>
  </div>
</template>
<script>
export default {
  name: "productionvalue",
  props: {
    option: Object,
    component: Object,
  },
  computed: {
    fontSize() {
      return (this.option.fontSize || 30) + "px";
    },
    lableFontSize() {
      return (this.option.lableFontSize || 30) + "px";
    },
    numTitle() {
      return this.option.numTitle;
    },
    numBackgroundColor0() {
      console.log('numBackgroundColor', this.option.numBackgroundColor0)
      return this.option.numBackgroundColor0||'linear-gradient(360deg, #55aaff 0%, #9c26ff 100%)';
    },
    numStr() {
      return this.dataChart ? this.dataChart : this.data;
    },
    percent(){
      let per = 20
      let data = this.dataChart ? this.dataChart : this.data;
      if(data.percent && data.percent > 20){
        per = data.percent
      }
      return per
    }
    
  },
  methods: {},
};
</script>
<style>
.numdiy-name {
  margin-right: 15px;
}
.numdiy-num {
  display: inline-block;
  padding: 2px 10px;
  margin-right: 10px;
  background: linear-gradient(270deg, #ff2944 0%, #ff6600 100%);
}
.progress {
  width: 400px;
  height: 40px;
  background: #bbffff;
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  display: inline-block;
  margin-left: 15px;
}
.percent {
  color: #ffffff;
  display: inline-block;
  font-size: 28px;
  height: 100%;
  border-radius: 20px;
  text-align: center;
  opacity: 1;
}
.value {
  font-size: 64px;
  font-family: PingFang-SC-Heavy-, PingFang-SC-Heavy;
  font-weight: normal;
  color: #00ffff;
  margin-left: 15px;
}
.root{
  display: flex;
  align-items: center;
}
</style>