<template>
  <div :key="id" :style="{fontSize:fontSize,color:labelColor}">
    <span>{{companyname}}</span>
    <span style="visibility:hidden;">{{dataQueryUser}}</span>
  </div>
</template>
<script>
export default {
  name: 'companyname',
  props: {
    option: Object,
    component: Object
  },
  computed: {
    fontSize () {
      return (this.option.fontSize || 50) + 'px'
    },
    labelColor () {
      return this.option.labelColor
    },
    dataQueryUser(){
      // console.log(this,90909090)
      return this.dataQuery&&this.dataQuery.companyId ? this.dataQuery.companyId : '2';
    },
  },
  methods: {
    getCompanyConfig(id){
      if(!id){
        id = 2;
      }
      let companyArr = [{id:11,name:'深圳'},
        {id:2,name:'武汉'},
        {id:20,name:'上海'},
        {id:21,name:'广州'},
        {id:14,name:'深圳龙岗'},
        {id:13,name:'北京'},
        {id:8,name:'西安'}]
      let needCompany = companyArr.filter(item=>item.id==id);
      console.log(needCompany,8989)
      this.companyname = needCompany[0].name+"分公司"
      // return needCompany[0].name+"分公司"
    },
  },
  watch:{
    dataQueryUser: {
      handler () {
        console.log(8989,9090)
        this.getCompanyConfig(this.dataQueryUser)
      },
      immediate: true
    }
  }
}
</script>
<style>
.numdiy-name {
  margin-right:15px;
}
.numdiy-num {
  display: inline-block;
  padding: 2px 10px;
  margin-right:10px;
  background: linear-gradient(270deg, #FF2944 0%, #FF6600 100%);
}
</style>