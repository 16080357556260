<template>
  <div class="home" style="background:">
    <div ref="chart1" style="width: 1400px;height:1400px;margin:0 auto"></div>
    <span style="visibility:hidden;">{{designData}}</span>
    <span style="visibility:hidden;">{{dataQueryUser}}</span>
  </div>
</template>
<script>
// import echarts from 'echarts';
// import china from 'echarts/map/js/china';
import { queryString } from "../../utils/utils";

export default {
  name: "branchmap",
  props: {
    option: Object,
    component: Object,
  },
  // data(){
  //   return {
  //     numstr:'898989'
  //   }
  // },
  computed: {
    designData(){
      return this.dataChart ? this.dataChart : this.data;
    },
    dataQueryUser(){
      return this.dataQuery&&this.dataQuery.companyId ? this.dataQuery.companyId : '2';
    },
  },
  methods: {
    getMapConfig() {
      const geoCoordMap = {
        // 上海: [121.48, 31.22],
        // 广州: [113.23, 23.16],
        // 深圳: [114.07, 22.62],
        // 深圳龙岗: [114.08, 22.32],
        // 西安: [108.95, 34.27],
        // 北京: [116.46, 39.92],
        // 武汉: [114.31, 30.52],
      };
      let data = [];
      for (let key in geoCoordMap) {
        data.push({ name: key, value: geoCoordMap[key] });
      }
      return data;
    },
    regions() {
      return [
        {
            name: '广州市', 
            itemStyle: {
                areaColor: '#0088e4',
                opacity: 1                            
            }
        },
        {
            name: '深圳市', 
            itemStyle: {
                areaColor: '#0088e4',
                opacity: 1                           
            }                        
        }
      ]
    },
    getDataConfig (id) {
      let config = {
        mapConfig:{

        },
        type:'',
        regions:[]
      }
      switch(parseInt(id)){
        case 11: case 14:
          config.mapConfig = [
            {name:'深圳',value:[114.07, 22.62]},
            {name:'深圳龙岗',value:[114.08, 22.32]}
            ];
          config.regions = [
            {
                name: '深圳市', 
                itemStyle: {
                    areaColor: '#0088e4',
                    opacity: 1                           
                }                        
            }
          ];
          config.type = "广东"
          break;
        case 13:
          config.mapConfig = [
            {name:'北京',value:[116.46, 39.92]},
            ];
          config.regions = [
            {
                name: '西城区', 
                itemStyle: {
                    areaColor: '#0088e4',
                    opacity: 1                           
                }                        
            }
          ];
          config.type = "北京"
          break;
        case 2:
          config.mapConfig = [
              {name:'武汉市',value:[114.31, 30.52]},
          ];
          config.regions = [
            {
                name: '武汉市', 
                itemStyle: {
                    areaColor: '#0088e4',
                    opacity: 1                           
                }                        
            }
          ];
          config.type = "湖北"
          break;
        case 20:
          config.mapConfig = [
            {name:'上海市',value:[121.48, 31.22]},
          ]
          config.regions = [
            {
                name: '徐汇区', 
                itemStyle: {
                    areaColor: '#0088e4',
                    opacity: 1                           
                }                        
            }
          ];
          config.type = "上海"
          break;
        case 21:
          config.mapConfig = [
            {name:'广州',value:[113.23, 23.16]},
          ]
          config.regions = [
            {
                name: '广州市', 
                itemStyle: {
                    areaColor: '#0088e4',
                    opacity: 1                           
                }                        
            }
          ];
          config.type = "广东"
          break;
        case 8:
          config.mapConfig = [
            {name:'西安市',value: [108.95, 34.27]},
          ]
          config.regions = [
            {
                name: '西安市', 
                itemStyle: {
                    areaColor: '#0088e4',
                    opacity: 1                           
                }                        
            }
          ];
          config.type = "陕西"
          break;
      }
      return config;
    },
    initChart() {
      this.myChart = window.echarts.init(this.$refs.chart1);
      let id = queryString('c')?queryString('c'):(this.dataQuery&&this.dataQuery.companyId?this.dataQuery.companyId:'11');
      // console.log(id,909090);
      let configData = this.getDataConfig(id);
      // console.log(configData,9090,id)
      // 使用刚指定的配置项和数据显示图表。
      var dataValue = configData.mapConfig;
      var data1 = dataValue;
      var option = {
        geo: {
          map: configData.type,
          roam: true, // 一定要关闭拖拽
          // zoom: 1.23,
          label: {
            normal: {
              show: false, //关闭省份名展示
              fontSize: "10",
              color: "rgba(0,0,0,0.7)",
            },
            emphasis: {
              show: false,
            },
          },
          itemStyle: {
            normal: {
              areaColor: "#0d0059",
              borderColor: "#389dff",
              borderWidth: 1, //设置外层边框
              // shadowBlur: 5,
              // shadowOffsetY: 8,
              // shadowOffsetX: 0,
              // shadowColor: "#01012a",
            },
            emphasis: {
              areaColor: "#00cef6",
              // shadowOffsetX: 0,
              // shadowOffsetY: 0,
              // shadowBlur: 5,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
          regions: configData.regions
        },
        tooltip: {
          trigger: "item",
          triggerOn: 'click',
          backgroundColor: ["#00cef6"],
          formatter: function(obj) {
            var value = obj.value;
            if (!value) return;
            return `<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;display:flex;justify-content:center;align-items:center">
              <img style="width:100px;height:100px;padding:10px" src="/img/user.png"/>
              <div style="margin-left:10px;font-size:25px;line-height:50px">
                <span>${obj.name}</span>
                <br/>
              </div>
              </div>`;
          },
          position: "top",
        },
        series: [
          {
            name: "",
            type: "scatter",
            coordinateSystem: "geo",
            data: dataValue,
            //   symbolSize: function(val) {
            //     return val[2] / 10;
            //   },
            symbol: "circle",
            symbolSize: 18,
            hoverSymbolSize: 10,
            encode: {
              value: 2,
            },
            label: {
              formatter: "{b}",
              position: "top",
              show: true,
            },
            itemStyle: {
              color: "#0efacc",
            },
            emphasis: {
              label: {
                show: false,
              },
            },
          },
          {
            name: "Top 5",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: data1,
            symbolSize: 15,
            tooltip: {
              show: true,
            },
            encode: {
              value: 2,
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
              color: "#0efacc",
              period: 9,
              scale: 5,
            },
            hoverAnimation: true,
            label: {
              formatter: "{b}",
              position: "top",
              show: true,
            },
            itemStyle: {
              color: "#0efacc",
              shadowBlur: 2,
              shadowColor: "#333",
            },
            zlevel: 1,
          },
        ],
      };
      this.echartOption = option;
      this.myChart.setOption(option);
      this.setToolTipShow(option.series[1].data)
    },

    setToolTipShow(data){
      let app = {
        currentIndex: -1,
      };

      let that = this;

      if (this.lineChartInterval) {
        window.clearInterval(that.lineChartInterval);
      }
      this.lineChartInterval = setInterval(function() {
        let dataLen = data.length;
        // 取消之前高亮的图形
        that.myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: app.currentIndex,
        });
        app.currentIndex = (app.currentIndex + 1) % dataLen;
        //console.log(app.currentIndex);
        // 高亮当前图形
        that.myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: app.currentIndex,
        });
        // 显示 tooltip
        that.myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: app.currentIndex,
        });
      }, 5000);
    },

    getCompanyConfig(){
      return [
        {id:11,name:'深圳'},
        {id:2,name:'武汉'},
        {id:20,name:'上海'},
        {id:21,name:'广州'},
        {id:14,name:'深圳龙岗'},
        {id:13,name:'北京'},
        {id:8,name:'西安'},
      ]
    },

    updateChart(data) {
      // console.log(data,Array.isArray(data),9090)
      let companyConfig = this.getCompanyConfig();
      let needCompanyArr = [];
      if(!data&&!Array.isArray(data)) return;
      let id = queryString('c')?queryString('c'):(this.dataQuery&&this.dataQuery.companyId?this.dataQuery.companyId:'11')
      data = data.filter(item=>item.companyId==id)
      data = data.map((item)=>{
        let companyName = companyConfig.filter(it=>it.id==item.companyId);
        if(!companyName.length){return item}
        item.companyName = companyName&&companyName[0].name;
        needCompanyArr.push({
          name:companyName[0].name,
          value:[item.lng,item.lat],
          ownerName:item.ownerName,
        })
        return item;
      })
      // console.log(data,9090)
      let tooltip = {
        trigger: "item",
        backgroundColor: ["#00cef6"],
        formatter: function(obj) {
          let designInfo = data
          if(!designInfo.length) return '';
          return `<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;display:flex;justify-content:center;align-items:center">
            <img style="width:100px;height:100px;padding:10px" src="/img/user.png"/>
            <div style="margin-left:10px;font-size:25px;line-height:50px">
              <span>${designInfo[0].houseAddr} ${designInfo[0].ownerName}</span>
              <br/>
            </div>
            </div>`;
        },
        position: "top",
      };
      if(!this.echartOption||!this.myChart) return false;
      // let originData = this.getMapConfig();
      this.echartOption.series[0].data = needCompanyArr;
      this.echartOption.series[1].data = needCompanyArr;
      this.echartOption.tooltip = tooltip;
      // console.log(this.echartOption,needCompanyArr,899)
      this.myChart.clear();
      this.myChart.setOption(this.echartOption);
      this.setToolTipShow(needCompanyArr)
    },
    updateChartByQuery(id){
      let data = this.getDataConfig(id);
      this.echartOption.geo.map = data.type;
      this.echartOption.geo.regions = data.regions;
      this.echartOption.series[0].data = data.mapConfig;
      this.echartOption.series[1].data = data.mapConfig;
      this.myChart.setOption(this.echartOption);
      this.updateChart(this.designData)
    }
  },
  mounted() {
    this.initChart();
  },
  watch:{
    designData: {
      handler () {
        // console.log(this,909090)
        this.updateChart(this.designData)
      },
      immediate: true
    },
    dataQueryUser: {
      handler () {
        // console.log(this,909090)
        this.updateChartByQuery(this.dataQueryUser)
      },
      immediate: true
    }
  }
};
</script>
<style>
.numdiy-name {
  margin-right: 15px;
}
.numdiy-num {
  display: inline-block;
  padding: 2px 10px;
  margin-right: 10px;
  background: linear-gradient(270deg, #ff2944 0%, #ff6600 100%);
}
</style>
