<template>
  <div class="home" style="background:">
    <div ref="chart" style="width: 1500px;height:1000px;margin:0 auto"></div>
    <span style="visibility:hidden;">{{designData}}</span>
  </div>
</template>
<script>
// import echarts from 'echarts';
// import china from 'echarts/map/js/china';

export default {
  name: "companymap",
  props: {
    option: Object,
    component: Object
  },
  computed: {
    designData() {
      return this.dataChart ? this.dataChart : this.data;
    }
  },
  methods: {
    getMapConfig() {
      const geoCoordMap = {
        上海: [121.48, 31.22],
        广州: [113.23, 23.16],
        深圳: [114.07, 22.62],
        深圳龙岗: [114.18, 22.82],
        西安: [108.95, 34.27],
        北京: [116.46, 39.92],
        武汉: [114.31, 30.52]
      };
      var data = [];
      for (var key in geoCoordMap) {
        data.push({ name: key, value: geoCoordMap[key] });
      }
      return data;
    },
    regions() {
      return [
        {
          name: "北京",
          itemStyle: {
            areaColor: "#00cef6",
            opacity: 1
          }
        },
        {
          name: "上海",
          itemStyle: {
            areaColor: "#0088e4",
            opacity: 1
          }
        },
        {
          name: "湖北",
          itemStyle: {
            areaColor: "#00cef6",
            opacity: 1
          }
        },
        {
          name: "陕西",
          itemStyle: {
            areaColor: "#0088e4",
            opacity: 1
          }
        },
        {
          name: "广东",
          itemStyle: {
            areaColor: "#0088e4",
            opacity: 1
          }
        }
      ];
    },
    initChart() {
      this.myChart = window.echarts.init(this.$refs.chart);
      // 使用刚指定的配置项和数据显示图表。
      var dataValue = this.getMapConfig();
      var data1 = dataValue;
      var option = {
        geo: {
          map: "china",
          roam: true, // 一定要关闭拖拽
          label: {
            normal: {
              show: false, //关闭省份名展示
              fontSize: "10",
              color: "rgba(0,0,0,0.7)"
            },
            emphasis: {
              show: false
            }
          },
          itemStyle: {
            normal: {
              areaColor: "#0d0059",
              borderColor: "#389dff",
              borderWidth: 1 //设置外层边框
              // shadowBlur: 5,
              // shadowOffsetY: 8,
              // shadowOffsetX: 0,
              // shadowColor: "#01012a",
            },
            emphasis: {
              areaColor: "#00cef6",
              // shadowOffsetX: 0,
              // shadowOffsetY: 0,
              // shadowBlur: 5,
              borderWidth: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          },
          regions: this.regions()
        },
        tooltip: {
          trigger: "item",
          backgroundColor: ["#00cef6"],
          formatter: function(obj) {
            var value = obj.value;
            if (!value) return;
            return `<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;display:flex;justify-content:center;align-items:center">
              <img style="width:100px;height:100px;padding:10px" src="/img/user.png"/>
              <div style="margin-left:10px;font-size:25px;line-height:50px">
                <span>${obj.name}</span>
                <br/>
              </div>
              </div>`;
          },
          position: "top"
        },
        series: [
          {
            name: "",
            type: "scatter",
            coordinateSystem: "geo",
            data: dataValue,
            //   symbolSize: function(val) {
            //     return val[2] / 10;
            //   },
            symbol: "circle",
            symbolSize: 18,
            hoverSymbolSize: 10,
            encode: {
              value: 2
            },
            label: {
              formatter: "{b}",
              position: "top",
              show: true
            },
            itemStyle: {
              color: "#0efacc"
            },
            emphasis: {
              label: {
                show: false
              }
            }
          },
          {
            name: "Top 5",
            type: "effectScatter",
            coordinateSystem: "geo",
            data: data1,
            symbolSize: 15,
            tooltip: {
              show: true
            },
            encode: {
              value: 2
            },
            showEffectOn: "render",
            rippleEffect: {
              brushType: "stroke",
              color: "#0efacc",
              period: 9,
              scale: 5
            },
            hoverAnimation: true,
            label: {
              formatter: "{b}",
              position: "top",
              show: true
            },
            itemStyle: {
              color: "#0efacc",
              shadowBlur: 2,
              shadowColor: "#333"
            },
            zlevel: 1
          }
        ]
      };
      this.echartOption = option;
      this.myChart.setOption(option);
      this.setToolTipShow(option.series[1].data);
    },

    setToolTipShow(data) {
      let app = {
        currentIndex: -1
      };

      let that = this;

      if (this.lineChartInterval) {
        window.clearInterval(that.lineChartInterval);
      }
      this.lineChartInterval = setInterval(function() {
        let dataLen = data.length;
        // 取消之前高亮的图形
        that.myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: app.currentIndex
        });
        app.currentIndex = (app.currentIndex + 1) % dataLen;
        //console.log(app.currentIndex);
        // 高亮当前图形
        that.myChart.dispatchAction({
          type: "highlight",
          seriesIndex: 0,
          dataIndex: app.currentIndex
        });
        // 显示 tooltip
        that.myChart.dispatchAction({
          type: "showTip",
          seriesIndex: 0,
          dataIndex: app.currentIndex
        });
      }, 5000);
    },

    getCompanyConfig() {
      return [
        { id: 11, name: "深圳" },
        { id: 2, name: "武汉" },
        { id: 20, name: "上海" },
        { id: 21, name: "广州" },
        { id: 14, name: "深圳龙岗" },
        { id: 13, name: "北京" },
        { id: 8, name: "西安" }
      ];
    },

    updateChart(data) {
      // console.log(data,9090)
      let companyConfig = this.getCompanyConfig();
      let needCompanyArr = [];
      data = data.map(item => {
        let companyName = companyConfig.filter(it => it.id == item.companyId);
        if (!companyName.length) {
          return item;
        }
        item.companyName = companyName&&companyName[0].name;
        needCompanyArr.push({
          name: companyName[0].name,
          value: [item.lng, item.lat],
          ownerName: item.ownerName,
        });
        return item;
      });
      // console.log(data,9090)
      let tooltip = {
        trigger: "item",
        backgroundColor: ["#00cef6"],
        formatter: function(obj) {
          console.log(obj,'2222222')
          let designInfo = data
          let i = obj.dataIndex?obj.dataIndex:0
          if (!designInfo.length) return "";
          return `<div style="border-bottom: 1px solid rgba(255,255,255,.3); font-size: 18px;display:flex;justify-content:center;align-items:center">
            <img style="width:100px;height:100px;padding:10px" src="/img/user.png"/>
            <div style="margin-left:10px;font-size:25px;line-height:50px">
              <span> ${designInfo[i].houseAddr} ${
            designInfo[i].ownerName
          }</span>
            </div>
            </div>`;
        },
        position: "top"
      };
      if (!this.echartOption || !this.myChart) return false;
      let originData = this.getMapConfig();
      this.echartOption.series[0].data = needCompanyArr.concat(originData);
      this.echartOption.series[1].data = needCompanyArr;
      this.echartOption.tooltip = tooltip;
      this.myChart.setOption(this.echartOption);
      this.setToolTipShow(needCompanyArr);
    }
  },
  mounted() {
    this.initChart();
  },
  watch: {
    designData: {
      handler() {
        this.updateChart(this.designData);
      },
      immediate: true
    }
  }
};
</script>
<style>
.numdiy-name {
  margin-right: 15px;
}
.numdiy-num {
  display: inline-block;
  padding: 2px 10px;
  margin-right: 10px;
  background: linear-gradient(270deg, #ff2944 0%, #ff6600 100%);
}
</style>
