<template>
  <div :key="id" :style="{fontSize:fontSize,color:'#fff'}">
    <span :style="{fontSize:lableFontSize}" class="numdiy-name">{{numTitle}}</span>
    <template v-for="(item,index) in numStr">
      <span class="numdiy-num" :key="index">{{item}}</span>
    </template>
  </div>
</template>
<script>
export default {
  name: 'numdiy',
  props: {
    option: Object,
    component: Object
  },
  computed: {
    fontSize () {
      return (this.option.fontSize || 30) + 'px'
    },
    lableFontSize () {
      return (this.option.lableFontSize || 30) + 'px'
    },
    numTitle () {
      return this.option.numTitle
    },
    numStr () {
      return this.dataChart?this.dataChart:this.data
    }
  },
  methods: {
  }
}
</script>
<style>
.numdiy-name {
  margin-right:15px;
}
.numdiy-num {
  display: inline-block;
  padding: 2px 10px;
  margin-right:10px;
  background: linear-gradient(270deg, #FF2944 0%, #FF6600 100%);
}
</style>