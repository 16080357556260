<template>
  <div :key="id" :style="tableStyle" class="linkabletable" ref="linkabletable">
    <el-table
      :data="originData"
      :header-row-style="headerStyle"
      :row-class-name="tableRowClassName"
      @cell-click="handle"
      style="width: 100%"
    >
      <template v-for="item in columnOptions">
        <el-table-column :key="item.prop" :prop="item.prop" :label="item.label">
          <template slot-scope="scope">
            <el-tooltip class="tooltip" effect="dark" placement="top">
                <span>{{scope.row[item.prop]}}</span>
                <div slot="content" >{{scope.row[item.prop]}}</div>
            </el-tooltip>
          </template>
        </el-table-column>
      </template>
    </el-table>
  </div>
</template>
<script>
export default {
  name: "linkabletable",
  props: {
    option: Object,
    component: Object
  },
  computed: {
    columnOptions() {
      return this.option.column;
    },
    originData() {
      return this.dataChart ? this.dataChart : this.data;
    },
    headerStyle() {
      return `background:${this.option.headerBackground};color:${this.option.headerColor}!important;font-size:${this.option.fontSize}px!important;text-align:center!important`;
    },
    tableStyle() {
      return `font-size:${this.option.fontSize}px!important;color:${this.option.bodyColor}!important;`;
    },
    tableColor() {
      return `font-size:${this.option.nthColor}px!important;color:${this.option.othColor}!important;`;
    },
    showTableNum() {
      return `${this.option.count};${this.component.height}`;
    },
    showScroll() {
      return `${this.option.scroll};${this.option.scrollCount};${this.option.scrollTime};`;
    }
  },
  created() {
    // console.log(this,9090)
    // setTimeout(() => {
    //   this.setDefaultConfig()
    // }, 1000);
  },
  methods: {
    setDefaultConfig(){
      let _this = this;
      setTimeout(()=>{
        _this.setTableBodyStyle();
        _this.setTableTbodyStyle();
        _this.setTableShowNum();
        _this.setTableScroll();
        _this.setBodyHeight();
      },100)

    },
    setBodyHeight(){
      const domHeight = this.component.height;
      const dom = this.$refs.linkabletable;
      const count = this.option.count;
      const trHeight = domHeight / (count + 1);
      // console.log(currentIndex,8989)
      if (!dom) return;
      let t1 = dom.querySelectorAll(".el-table__body-wrapper");
      t1[0].style.height = (domHeight-trHeight+10)+"px"
    },
    setTableBodyStyle() {
      const options = this.option;
      const dom = this.$refs.linkabletable;
      if (!dom) return;
      // 动态设置样式
      let trlist = dom.querySelectorAll("tbody > tr");
      for (let i = 0; i < trlist.length; i++) {
        trlist[i].style.fontSize = `${options.fontSize}px`;
        trlist[i].style.color = `${options.bodyColor}`;
      }
    },
    setTableTbodyStyle() {
      const options = this.option;
      const dom = this.$refs.linkabletable;
      if (!dom) return;
      // 动态设置样式
      let linkabletable2list = dom.querySelectorAll(".linkabletable-2");
      for (let i = 0; i < linkabletable2list.length; i++) {
        linkabletable2list[i].style.background = `${options.nthColor}`;
      }
      let linkabletable1list = dom.querySelectorAll(".linkabletable-1");
      for (let i = 0; i < linkabletable1list.length; i++) {
        linkabletable1list[i].style.background = `${options.othColor}`;
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "linkabletable-2";
      } else {
        return "linkabletable-1";
      }
    },
    setTableShowNum() {
      // 根据行数计算高度
      const domHeight = this.component.height;
      const count = this.option.count;
      const trHeight = domHeight / (count + 1);
      const dom = this.$refs.linkabletable;
      if (!dom) return;
      const trlist = dom.querySelectorAll("tr,th");
      for (let i = 0; i < trlist.length; i++) {
        trlist[i].style.height = `${trHeight}px`;
      }
      // console.log(this.option,trHeight,8989)
    },
    setTableScroll() {
      //  获取表格体
      if (this.a) {
        clearInterval(this.a);
      }
      if (!this.option.scroll) {
        return false;
      }
      // 有超出的部分
      const count = this.option.count;
      const originData = this.originData;
      const dataLength = originData.length;
      // console.log(originData, dataLength, count, 8989);
      if (dataLength <= count) {
        return;
      }
      const moveNum = dataLength - count;
      let time = this.option.scrollTime;
      // console.log(moveNum, 8989);
      let scrollCount = this.option.scrollCount;
      if (scrollCount > moveNum) {
        scrollCount = moveNum;
      }
      let t = scrollCount;
      this.a = setInterval(() => {
        this.tableMove(moveNum, t, time);
        if (t > moveNum) {
          t = scrollCount;
        } else {
          t = t + scrollCount;
        }
      }, time);
    },
    tableMove(moveNum, currentIndex, time) {
      const domHeight = this.component.height;
      const count = this.option.count;
      const trHeight = domHeight / (count + 1);
      const dom = this.$refs.linkabletable;
      // console.log(currentIndex,8989)
      if (!dom) return;
      let t = dom.querySelectorAll(".el-table__body");
      let t1 = dom.querySelectorAll(".el-table__body-wrapper");
      t1[0].style.height = (domHeight-trHeight+10)+"px"
      if (currentIndex <= moveNum) {
        t[0].style.transition = "all .5s";
        t[0].style.marginTop = `-${trHeight * currentIndex}px`;
      }
      if (currentIndex == moveNum) {
        setTimeout(() => {
          t[0].style.transition = "all 0.5s ease 0s";
          t[0].style.marginTop = "0";
        }, time);
      }
    },
    handle(row, column, cell, event) {
      // console.log(row, column, cell, event, 8989);
      if (!this.option.tableLink) return;
      const regexMark = /\?/g;
      console.log(row,column,cell,900)
      let startMark = regexMark.test(this.option.tableLink)?'&':'?'
      let url = `${this.option.tableLink}${startMark}${
        this.option.queryName
          ? this.option.queryName + "=" + row[this.option.queryKey]
          : ""
      }&${
        this.option.queryName1
          ? this.option.queryName1 + "=" + column[this.option.queryKey1]
          : ""
      }`;
      let qsStr = this.qs(this.dataQuery)
      url = `${url}&${qsStr}`
      const regex = /https\:\/\/|http\:\/\//g;
      if (!regex.test(url)) {
        alert("请配置正确的域名");
        return;
      }
      window.open(url);
      // console.log(row, column, cell, event,9090)
    },
    qs(obj){
      if(!obj) return ''
      let str = ''
      let keysArr = Object.keys(obj);
      if(!keysArr.length) return str;
      keysArr.map(item=>{
        str = str?(`${str}&${item}=${obj[item]}`):`${item}=${obj[item]}`;
      })
      return str;
    },
    debounce(fn, wait) {
      let timer;
      return function () {
          clearTimeout(timer);
          timer = setTimeout(() => {
              fn.apply(this, arguments)   // 把参数传进去
          }, wait);
        }
    }
  },
  watch: {
    tableStyle: {
      handler() {
        this.setTableBodyStyle();
      },
      immediate: true
    },
    tableColor: {
      handler() {
        this.setTableTbodyStyle();
      },
      immediate: true
    },
    showTableNum: {
      handler() {
        this.setTableShowNum();
      },
      immediate: true
    },
    showScroll: {
      handler() {
        this.setTableScroll();
      },
      immediate: true
    },
    originData:{
      handler() {
        console.log(8989,'数据更新')
        // if (this.a) {
        //   clearInterval(this.a);
        // }
        this.setDefaultConfig();
        // this.debounce(this.setDefaultConfig,2000)
      },
      immediate: true
    }
  }
};
</script>
<style>
.linkabletable {
  overflow: hidden;
}
.linkabletable table th,
.linkabletable table td {
  text-align: center;
}
.el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: unset;
}
.linkabletable table tr:hover,
.linkabletable table th:hover {
  background: unset;
}
.linkabletable .el-table th>.cell, .linkabletable .el-table .cell{
  line-height: 48px;
  font-weight: 100;
  font-weight: 400;
  white-space: nowrap
}
.linkabletable .el-table__header-wrapper {
  position: relative;
  z-index: 100;
}
.linkabletable .el-table__body-wrapper {
  position: relative;
  z-index: 1;
  overflow: hidden;
  height: 100px;
}
</style>