import Vue from "vue";
import ElementUI from "element-ui";
import axios from "./axios";
import "element-ui/lib/theme-chalk/index.css";
import dataV from "@jiaminghi/data-view";
// import { getCookie, queryString } from "./utils/utils";
import router from "./router.js";
import App from "./App.vue";
import "./styles/common.scss";
import { url } from "@/config";
import "./utils/number";
import "@/mock/";
//注册自定义组件
import "./components/";
//导入主题文件
import "@/theme/index.js";
// console.log(window, 9090);
document.domain = "inewhome.com";
// 判断权限
// let userInfo = getCookie("userInfo");
// let Token = getCookie("INewHome-Token");
// if (userInfo) {
//   userInfo = JSON.parse(userInfo);
// }
// if (!Token || !userInfo) {
//   // console.log(userInfo,Token,9090)
//   window.location.href = `http://cloud.inewhome.com/#/login?redirectUrl=http://data.inewhome.com`;
// }
window.axios = axios;
Vue.config.productionTip = false;
Vue.prototype.url = url;
Vue.use(window.AVUE, {
  size: "mini",
});
Vue.use(ElementUI);
Vue.use(dataV);
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
